import { useFeatureFlag } from 'configcat-react'
import { Fragment } from 'react'

import { FloraButton as Button, FloraButtonProps } from '@grupoboticario/flora-react'

import { useCurrentAttendanceContext } from '@register-attendance/providers'

import { useEvents } from '@shared/events'
import { useDrawer, useTabulation } from '@shared/hooks'
import { useTranslation } from '@shared/i18n'

import { useResellerData } from '../../hooks'
import { Container } from './attendance-actions.styles'
import { ConsultSimilarDrawer } from './consult-similar-drawer'

const AttendanceActions: React.FC<FloraButtonProps> = (props) => {
  const { t } = useTranslation('attendanceActions')
  const { isReleasedAttendance, model, loading, error } = useResellerData()
  const { currentAttendance, setCurrentAttendance } = useCurrentAttendanceContext()
  const { value: showRupture } = useFeatureFlag('useV2AdditionalInfo', false)
  const events = useEvents()

  const setIsReleased = isReleasedAttendance ? 'isReleased' : 'isNotReleased'

  const tabulation = useTabulation({
    attendanceId: currentAttendance?.attendanceId,
    reId: model?.general.geraId,
    reName: model?.general.name,
  })

  const { open } = useDrawer({
    title: t('drawerTitle'),
    content: <ConsultSimilarDrawer />,
  })

  if (!currentAttendance || loading || error) {
    return <Fragment />
  }

  const config = {
    baseOnClick: () => events.clickAttendanceFinishButton(),
    isReleased: {
      variant: 'standard',
      text: t('endAttendance'),
      onclick: (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        tabulation.open()
        config.baseOnClick()
      },
    },
    isNotReleased: {
      variant: 'ghost',
      text: t('endConsultation'),

      onclick: (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setCurrentAttendance(null)
        config.baseOnClick()
      },
    },
  }

  const handleConsultSimilarClick = (): void => {
    open()
  }

  const renderConsultSimilarButton = showRupture && (
    <Button hierarchy="secondary" size="small" {...props} onClick={handleConsultSimilarClick}>
      <span>{t('consultSimilar.mobile')}</span>
      <span>{t('consultSimilar.desktop')}</span>
    </Button>
  )

  return (
    <Container>
      {renderConsultSimilarButton}

      <Button
        size="small"
        variant={config[setIsReleased].variant}
        onClick={config[setIsReleased].onclick}
        {...props}
      >
        {config[setIsReleased].text}
      </Button>
    </Container>
  )
}

export { AttendanceActions }
