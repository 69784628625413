import {
  ActionType,
  CategoryType,
  InteractionDetail,
  type EventBaseData,
  type EventTracker,
} from './events.types'

interface PromotionEvents {
  clickPromotionsTab: () => void
  clickDropdownPromotion: (brand: string) => void
  clickCardPromotion: (brand: string) => void
  clickShowMorePromotionsButton: () => void
}

const createPromotionEvents = (tracker: EventTracker, data: EventBaseData): PromotionEvents => ({
  clickPromotionsTab: function () {
    tracker.track({
      event: 'event',
      action: ActionType.clickButton,
      category: CategoryType.promotional,
      label: 'menu_promocoes',
    })
  },
  clickDropdownPromotion: function (brand: string) {
    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        event_name: 'interaction_atendimento',
        cd_interaction_detail: `${InteractionDetail.click}:promocao-ver-detalhes`,
        cd_codigo_atendimento: data.attendanceId,
        cd_codigo_representante: data.reId,
        cd_section: `promocoes:${brand}`,
      },
    })
  },

  // New Page
  clickCardPromotion: function (brand: string) {
    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        event_name: 'interaction_atendimento',
        cd_interaction_detail: `promocao:click-card-promocao:${brand}`,
      },
    })
  },

  clickShowMorePromotionsButton: function () {
    tracker.track({
      event: 'analytics-event',
      event_parameters: {
        event_name: 'interaction_atendimento',
        cd_interaction_detail: 'promocao:click:mostrar-mais-promocoes',
      },
    })
  },
})

export { createPromotionEvents, type PromotionEvents }
