import { useCallback } from 'react'

import { FloraTypography as Typography, Tag } from '@grupoboticario/flora-react'

import { useTranslation } from '@shared/i18n'
import { useApiData } from '@shared/api'
import { PromotionDetailDTO } from '@shared/types/PromotionDetailsDTO'

import { Card, ErrorState, LoadingState, NotFoundState } from '@shared/components'

import { CardDescription, CardHeader } from '../promotions.styles'
import { renderHeaderColor } from '../promotions.utils'

import { PromotionDetailProps } from './promotion-detail.types'
import { cardCustomStyle, Container, ImageContainer } from './promotion-detail.styles'

const PromotionDetail = ({
  brand,
  campaignId,
  classification,
  description,
  isMultiOrganization = true,
  slug,
}: PromotionDetailProps): JSX.Element => {
  const { t } = useTranslation('promotions', { keyPrefix: 'detail' })

  const getApiParams = useCallback(() => ({ campaignId: campaignId ?? '' }), [campaignId])

  const { data, loading, error, notFound, filled } = useApiData<PromotionDetailDTO>({
    dataSource: 'getPromotionBanner',
    fetchOnMount: true,
    getApiParams,
  })

  return (
    <Container>
      <Card css={cardCustomStyle}>
        <CardHeader type={renderHeaderColor(isMultiOrganization, slug as string)}>
          <Typography
            as="h5"
            color="$nonInteractivePredominant"
            fontSize="bodySmallShort"
            fontWeight="medium"
          >
            {isMultiOrganization ? t('multibrand') : brand}
          </Typography>

          {classification === 'CRM' && (
            <Tag size="small" shape="ghost" variant="assorted9" css={{ width: 'fit-content' }}>
              {t(`classification.${classification.toLowerCase()}`)}
            </Tag>
          )}
        </CardHeader>

        <CardDescription as="p">{description}</CardDescription>
      </Card>

      <ImageContainer>
        <Typography
          fontSize="bodyLargeStandard"
          fontWeight="medium"
          css={{ color: '$nonInteractivePredominant' }}
        >
          {t('imageTitle')}
        </Typography>

        {loading && <LoadingState />}
        {notFound && <NotFoundState text={t('notFound')} />}
        {error && <ErrorState />}

        {filled && data && (
          <img src={data.imageUrl} alt={data.description} style={{ maxWidth: '100%' }} />
        )}
      </ImageContainer>
    </Container>
  )
}

export { PromotionDetail }
