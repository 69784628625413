import { Flex, styled } from '@grupoboticario/flora-react'

const Header = styled('header', {
  backgroundColor: '$backgroundPrimary',
  padding: '$4',
})

const CardAddress = styled('button', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  minHeight: '68px',
  justifyContent: 'space-between',
  backgroundColor: '$backgroundPrimary',
  borderRadius: '$medium',
  borderWidth: 0,
  textAlign: 'left',
  padding: '$4 $4',

  '&:hover': {
    '&::before': {
      borderColor: '$linkDefault',
    },
  },

  '&::before': {
    content: '',
    position: 'absolute',
    inset: 0,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderRadius: '$medium',
    transitionProperty: 'border-color',
    transitionDuration: '0.2s',
    transitionTimingFunction: 'ease-out',
    pointerEvents: 'none',
  },

  '> span': {
    '&:first-of-type, &:last-of-type': {
      position: 'absolute',
      top: 'calc(50% - 24px / 2)',
    },

    '&:first-of-type': {
      left: '$4',
    },

    '&:last-of-type': {
      right: '$4',
    },
  },
})

const CardAddressContent = styled(Flex, {
  flexDirection: 'column',
  gap: '$2',
  padding: '$4',
})

const TextContainer = styled('div', {
  width: '80%',
})

const ImageContainer = styled('div', {
  width: '10%',
})

export { CardAddress, CardAddressContent, Header, TextContainer, ImageContainer }
