import { CSS, Flex, styled } from '@grupoboticario/flora-react'

const cardCustomStyle: CSS = {
  height: 'fit-content',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '$nonInteractiveOutline',

  '&::before': {
    display: 'none',
  },
}

const Container = styled(Flex, {
  flexDirection: 'column',
  gap: '$6',
})

const ImageContainer = styled(Flex, {
  flexDirection: 'column',
  gap: '$4',
  padding: '$6',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '$nonInteractiveOutline',
  borderRadius: '$medium',
})

export { cardCustomStyle, Container, ImageContainer }
