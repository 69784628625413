import { Fragment, useState } from 'react'

import {
  FloraButton as Button,
  FloraTypography as Typography,
  Tag,
} from '@grupoboticario/flora-react'

import { ChevronRightIcon } from '@grupoboticario/flora-react-icons'

import { useTranslation } from '@shared/i18n'
import { Card, CardList, ErrorState, LoadingState, NotFoundState } from '@shared/components'
import { useEvents } from '@shared/events'

import { PromotionDetail } from './promotion-detail'
import { PromotionDrawer } from './promotion-drawer'

import { CardDescription, CardHeader, cardStyle } from './promotions.styles'
import { renderHeaderColor } from './promotions.utils'
import { useResellersPromotions } from './promotions.hooks'
import { PromotionProps, PromotionsProps } from './promotions.types'

const Promotions = ({ resellerId }: PromotionsProps): JSX.Element => {
  const { t } = useTranslation('promotions')
  const events = useEvents()

  const [selectedPromotion, setSelectedPromotion] = useState<PromotionProps | null>()

  const [openDrawer, setOpenDrawer] = useState<boolean>(false)

  const onOpenChange = (): void => {
    setSelectedPromotion(null)
    setOpenDrawer(false)
  }

  const { list, loading, error, getPromotions, hasMore, empty, notFound, filled } =
    useResellersPromotions(resellerId)

  const onShowMore = (): void => {
    getPromotions()
    events.clickShowMorePromotionsButton()
  }

  const cardList = list?.map((item, index) => {
    const onClickCard = () => {
      setSelectedPromotion({
        campaignId: item.campaignId,
        classificationId: item.classificationId,
        classificationName: item.classificationName,
        description: item.description,
        isMultiOrganization: item.isMultiOrganization,
        name: item.name,
        slug: item.slug,
      })

      setOpenDrawer(true)
      events.clickCardPromotion(item.name)
    }

    return (
      <Card
        key={`${index}-${item.campaignId}`}
        css={cardStyle}
        ariaLabel={t('cardAriaLabel', { name: item.name })}
        onClick={onClickCard}
      >
        <CardHeader as="header" type={renderHeaderColor(item.isMultiOrganization, item.slug)}>
          <Typography
            as="h5"
            color="$nonInteractivePredominant"
            fontSize="bodySmallShort"
            fontWeight="medium"
          >
            {item.isMultiOrganization ? t('detail.multibrand') : item.name}
          </Typography>

          {item.classificationName === 'CRM' && (
            <Tag size="small" shape="ghost" variant="assorted9" css={{ width: 'fit-content' }}>
              {t(`detail.classification.${item.classificationName.toLowerCase()}`)}
            </Tag>
          )}

          <Button
            has="iconOnly"
            hierarchy="secondary"
            icon={<ChevronRightIcon size="8px" color="$actionableDefault" />}
            size="small"
            tabIndex={-1}
          />
        </CardHeader>

        <CardDescription as="p" shortDescription>
          {item.description}
        </CardDescription>
      </Card>
    )
  })

  const renderNotFound = (filled && notFound) || (filled && list.length === 0)

  return (
    <Fragment>
      {loading && <LoadingState css={{ height: 'calc(100vh - 200px)' }} />}

      {renderNotFound && <NotFoundState />}

      {error && <ErrorState />}

      {filled && list.length > 0 && (
        <Fragment>
          <Typography
            as="h4"
            fontSize="bodyLargeStandard"
            fontWeight="medium"
            color="$nonInteractivePredominant"
            css={{ marginBottom: '$7 !important' }}
          >
            {t('title')}
          </Typography>

          <CardList
            empty={empty}
            showMoreLabel={t('seeMore')}
            loading={loading}
            error={error}
            hasMore={hasMore}
            onShowMore={onShowMore}
          >
            {cardList}
          </CardList>

          <PromotionDrawer open={openDrawer} onOpenChange={onOpenChange}>
            <PromotionDetail
              brand={selectedPromotion?.name}
              campaignId={selectedPromotion?.campaignId}
              classification={selectedPromotion?.classificationName}
              description={selectedPromotion?.description}
              isMultiOrganization={selectedPromotion?.isMultiOrganization}
              slug={selectedPromotion?.slug}
            />
          </PromotionDrawer>
        </Fragment>
      )}
    </Fragment>
  )
}

export { Promotions, renderHeaderColor }
