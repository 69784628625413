import { Flex, styled } from '@grupoboticario/flora-react'

const Container = styled(Flex, {
  flexDirection: 'column',
  width: '100%',
  gap: '$2',

  '@tablet': {
    width: '404px',
  },
})

export { Container }
