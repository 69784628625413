import { useCallback, useEffect, useRef, useState } from 'react'

import { QueryParams } from '@grupoboticario/vdi-mfe-utils'

import { AsyncState, MetaState, useApi, useAsyncState } from '@shared/api'

import { PromotionsDTO, PromotionProps } from './promotions.types'

interface UseResellerPromotions extends AsyncState {
  list: PromotionProps[]
  hasMore: boolean
  empty: boolean
  getPromotions: () => void
}

const useResellersPromotions = (resellerId: string): UseResellerPromotions => {
  const [promotions, setPromotions] = useState(initialPromotionsState)
  const asyncState = useAsyncState(promotions.status)
  const pagination = useRef({ page: 1, limit: 12 })
  const api = useApi()

  const getPromotionsApi = useCallback(
    async (params: QueryParams) => {
      setPromotions((s) => ({ ...s, status: MetaState.LOADING }))
      const result = await api.getPromotions<PromotionsDTO>({ ...params, resellerId })

      if (result.ok) {
        setPromotions((s) => {
          const list = s.list.concat(result.value.promotions)
          const hasMore = pagination.current.page < result.value.pagination.totalPages

          return {
            list,
            hasMore,
            status: MetaState.FILLED,
          }
        })
      } else {
        setPromotions((s) => ({ ...s, hasMore: true, status: MetaState.ERROR }))
      }
    },
    [api, resellerId],
  )

  const getPromotions = () => {
    if (asyncState.filled) {
      pagination.current.page++
    }
    getPromotionsApi({ ...pagination.current })
  }

  useEffect(() => {
    getPromotionsApi(pagination.current)
  }, [getPromotionsApi])

  return {
    ...asyncState,
    empty: asyncState.filled && promotions.list.length <= 0,
    list: promotions.list,
    hasMore: promotions.hasMore,
    getPromotions,
  }
}

interface PromotionsState {
  list: PromotionProps[]
  status: MetaState
  hasMore: boolean
}

const initialPromotionsState: PromotionsState = {
  list: [],
  hasMore: false,
  status: MetaState.IDLE,
}

export { useResellersPromotions }
