import { styled } from '@grupoboticario/flora-react'

const CardContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '$2',
  padding: '$4',
  backgroundColor: '$backgroundPrimary',
  borderRadius: '$medium',
  zIndex: 1,

  '&:hover, &:focus-visible': {
    '&::before': {
      borderColor: '$assorted9',
    },
  },

  '&::before': {
    content: '',
    position: 'absolute',
    inset: 0,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'transparent',
    pointerEvents: 'none',
    borderRadius: '$medium',
    transitionDuration: '0.2s',
    transitionProperty: 'border-color',
    transitionTimingFunction: 'ease-out',
  },
})

const CardDivider = styled('hr', {
  backgroundColor: '$nonInteractiveOutline',
  height: 2,
  margin: 0,
  border: 0,
})

export { CardContainer, CardDivider }
