import { useEffect, useRef } from 'react'

import { Flex, FloraTypography as Typography } from '@grupoboticario/flora-react'
import { ExclamationMarkHexagonIcon, PersonPhoneCardIcon } from '@grupoboticario/flora-react-icons'
import { useFeatureFlag } from 'configcat-react'

import { useCurrentAttendanceContext } from '@register-attendance/providers'
import { EmptyState, ErrorState } from '@shared/components'
import { useIsMobile } from '@shared/hooks'
import { useTranslation } from '@shared/i18n'
import { useAppContext } from '@shared/providers'

import { useResellerDataTabControl } from './hooks'
import { AttendanceActions, HeaderMobile, Tabs } from './modules'
import { ResellerDataProvider } from './providers'
import { Box } from './reseller-data.styles'
import { ResellerDataTab } from './reseller-data.types'

const ResellerData = (): JSX.Element => {
  const { activeTab, setActiveTab } = useResellerDataTabControl()
  const { value: isV2 } = useFeatureFlag('useV2AdditionalInfo', false)
  const { currentAttendance } = useCurrentAttendanceContext()
  const wrapperElement = useRef<HTMLElement>(null)
  const { t } = useTranslation('resellerData')
  const { appState } = useAppContext()
  const isMobile = useIsMobile()

  useEffect(() => {
    setActiveTab(ResellerDataTab.GENERAL)
    wrapperElement.current?.focus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAttendance])

  if (appState.withoutStructure) {
    return (
      <ErrorState
        title={t('errorState.title')}
        description={t('errorState.subtitle')}
        icon={<ExclamationMarkHexagonIcon />}
      />
    )
  }

  if (!currentAttendance) {
    return (
      <Flex justify="center" align="center" css={{ height: '100%' }}>
        <EmptyState
          size="lg"
          icon={<PersonPhoneCardIcon />}
          title={t('emptyState.title')}
          description={t('emptyState.subtitle')}
        />
      </Flex>
    )
  }

  const onTabChange = (value: string): void => {
    setActiveTab(value)
  }

  return (
    <ResellerDataProvider resellerId={currentAttendance.resellerId} isV2={isV2}>
      <Box ref={wrapperElement} tabIndex={0}>
        {isMobile ? (
          <HeaderMobile />
        ) : (
          <Flex direction="row" align="center" justify="space-between" css={{ padding: '$4 0' }}>
            <Typography as="h1" fontSize="subtitle" fontWeight="medium">
              {t('desktopTitle')}
            </Typography>
            <AttendanceActions />
          </Flex>
        )}

        <Tabs
          onTabChange={onTabChange}
          activeTab={activeTab}
          resellerId={currentAttendance.resellerId}
        />
      </Box>
    </ResellerDataProvider>
  )
}

export { ResellerData }
