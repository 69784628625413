import { Flex, FloraTypography as Typography, styled, CSS } from '@grupoboticario/flora-react'

import { PromotionsSlug, PromotionsColors } from './promotions.types'

const renderColorType = (type: PromotionsSlug) => ({
  '&::before': {
    backgroundColor: PromotionsColors[type],
  },
})

const CardDescription = styled(Typography, {
  color: '$nonInteractivePredominant',
  fontSize: '$exceptionsAuxiliar !important',
  fontWeight: '$medium !important',
  lineHeight: '$short',

  variants: {
    shortDescription: {
      true: {
        display: '-webkit-box',
        overflow: 'hidden',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
      },
      false: {},
    },
  },
})

const CardHeader = styled(Flex, {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '$1',
  padding: '0 $10',

  variants: {
    type: {
      bot: {
        ...renderColorType(PromotionsSlug.BOT),
      },
      oui: {
        ...renderColorType(PromotionsSlug.OUI),
      },
      qdb: {
        ...renderColorType(PromotionsSlug.QDB),
      },
      eud: {
        ...renderColorType(PromotionsSlug.EUD),
      },
      mlt: {
        ...renderColorType(PromotionsSlug.MLT),
      },
    },
  },

  '&::before': {
    content: '',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '$8',
    height: '$8',
    backgroundColor: '$nonInteractivePredominant',
    borderRadius: '50%',
  },

  button: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'none',
    width: 'fit-content',
    opacity: 0,
    transitionDuration: '0.2s',
    transitionProperty: 'opacity',
    transitionTimingFunction: 'ease-out',
    pointerEvents: 'none',

    '@desktop': {
      display: 'inline-flex',
    },
  },
})

const cardStyle: CSS = {
  cursor: 'pointer',

  '&:hover, &:focus-visible': {
    button: {
      opacity: 1,
    },
  },
}

export { CardDescription, CardHeader, cardStyle }
