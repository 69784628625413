interface StepStateMachine {
  on: {
    next: string | null
    back: string | null
  }
}

const machine: Record<string, StepStateMachine> = {
  address: {
    on: {
      next: 'search',
      back: null,
    },
  },
  search: {
    on: {
      next: null,
      back: 'address',
    },
  },
}

export { machine }
