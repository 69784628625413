import React from 'react'

import {
  FloraTypographyProps,
  Image,
  Tag,
  FloraTypography as Typography,
} from '@grupoboticario/flora-react'

import { Alert } from '@grupoboticario/flora-team-react-alert'
import { toCurrency } from '@grupoboticario/vdi-mfe-utils'

import { useTranslation } from '@shared/i18n'

import {
  ContainerProductText,
  ContainerSKU,
  ProductContainer,
  ProductImageContainer,
  ProductsContainer,
  ProductsList,
  Title,
} from './products.styles'

import { ProductProps, ProductsProps } from './products.types'
import { BrokenImageIcon } from '@grupoboticario/flora-react-icons'

const Products: React.FC<ProductsProps> = ({ products, title, emptyDescription }) => (
  <ProductsContainer>
    <Title as="h6">{title}</Title>

    {products.length <= 0 && (
      <Alert hideCloseButton description={emptyDescription} status="info" size="small" />
    )}

    {products.length > 0 && (
      <ProductsList>
        {products.map((p) => (
          <Product key={p.sku} product={p} />
        ))}
      </ProductsList>
    )}
  </ProductsContainer>
)

const Product: React.FC<ProductProps> = ({ product }) => {
  const { t } = useTranslation('product')

  const TypographyProps: FloraTypographyProps = {
    color: 'nonInteractivePredominant',
    fontSize: 'exceptionsAuxiliar',
    fontWeight: 'regular',
  }

  const tagColor: Record<string, 'assorted9' | 'success' | 'disabled' | 'error' | 'alert'> = {
    available: 'success',
    unavailable: 'disabled',
    critical: 'error',
    warning: 'alert',
  }

  return (
    <ProductContainer as="li">
      <ProductImageContainer>
        {product.imageObject && <Image src={product.imageObject?.small} alt={product.name} />}
        {!product.imageObject?.small && <BrokenImageIcon color="$disabledDark" />}
      </ProductImageContainer>
      <ContainerProductText>
        <ContainerSKU>
          {t('sku', { sku: product.sku })}

          <Tag size="small" variant={tagColor[product.status ?? 'disabled']}>
            {t(product.status ?? 'unavailable')}
          </Tag>
        </ContainerSKU>

        <Typography {...TypographyProps}>{product.name}</Typography>
        <Typography {...TypographyProps}>{toCurrency(Number(product.price))}</Typography>
      </ContainerProductText>
    </ProductContainer>
  )
}

export { Products }
