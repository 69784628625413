import { Fragment, useCallback } from 'react'

import { FloraTypography as Typography } from '@grupoboticario/flora-react'
import { ArrowRightIcon, StoreIcon, TruckIcon } from '@grupoboticario/flora-react-icons'

import { useApiData } from '@shared/api'
import { ErrorState, LoadingState, NotFoundState } from '@shared/components'
import { useTranslation } from '@shared/i18n'

import { useCurrentAttendanceContext } from '@register-attendance/providers'

import { machine } from '../consult-similar-drawer'

import { CardAddress, Header } from './address-step.styles'
import { AddressStepProps, DeliveryType, ResellerDistributionCenterDTO } from './address-step.types'

const AddressStep = ({
  changeStep,
  setSelectedAddress,
  show,
}: AddressStepProps): JSX.Element | null => {
  const { t } = useTranslation('addressStep')
  const { currentAttendance } = useCurrentAttendanceContext()

  const getApiParams = useCallback(
    () => ({ resellerId: currentAttendance ? currentAttendance.resellerId : '' }),
    [currentAttendance],
  )

  const { data, loading, filled, error, refetch, notFound } =
    useApiData<ResellerDistributionCenterDTO>({
      dataSource: 'getDistributionCenter',
      getApiParams,
    })

  if (!show) {
    return null
  }

  const isLoaded = !loading && filled && !notFound

  const renderListAddresses =
    isLoaded &&
    data?.resellerAddresses.map(({ id, deliveryType, address }) => {
      const isDeliveryAddress = deliveryType === DeliveryType.ADDRESS

      const renderDeliveryTypeIcon = isDeliveryAddress ? (
        <TruckIcon size="24px" color="$nonInteractivePredominant" />
      ) : (
        <StoreIcon size="24px" color="$nonInteractivePredominant" />
      )

      const renderDeliveryTypeText = isDeliveryAddress ? t('receiveDeliveryAddress') : t('pickUp')

      const handleClick = () => {
        changeStep(machine.address.on.next)
        setSelectedAddress({ type: renderDeliveryTypeText, name: address })
      }

      return (
        <CardAddress
          key={id}
          onClick={handleClick}
          aria-label={`${renderDeliveryTypeText}: ${address}`}
        >
          {renderDeliveryTypeIcon}
          <Typography
            as="span"
            color="$nonInteractivePredominant"
            fontSize="bodySmallShort"
            fontWeight="medium"
          >
            {renderDeliveryTypeText}
            <Typography
              as="span"
              color="$nonInteractivePredominant"
              fontSize="exceptionsAuxiliar"
              fontWeight="regular"
              css={{ display: 'block', paddingTop: '$1' }}
            >
              {address}
            </Typography>
          </Typography>
          <ArrowRightIcon size="24px" color="$linkDefault" />
        </CardAddress>
      )
    })

  const renderHeader = isLoaded && (
    <Header>
      <Typography
        color="$nonInteractivePredominant"
        fontSize="bodyLargeStandard"
        fontWeight="medium"
      >
        {t('title')}
        <Typography
          color="$nonInteractivePredominant"
          fontSize="bodyLargeStandard"
          fontWeight="regular"
        >
          {t('subtitle')}
        </Typography>
      </Typography>
    </Header>
  )

  return (
    <Fragment>
      {renderHeader}

      {loading && <LoadingState containerHeight="calc(100vh - 82px)" />}
      {error && <ErrorState onButtonClick={refetch} />}
      {notFound && <NotFoundState />}

      {renderListAddresses}
    </Fragment>
  )
}

export { AddressStep }
