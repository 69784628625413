import { DataBlockDescription } from './data-block-description'
import { DataBlockTitle } from './data-block-title'

import { DataBlockCanCopy, type DataBlockProps } from './data-block.types'

import {
  Avatar,
  AvatarContainer,
  Container,
  Content,
  Heading,
  IconContainer,
} from './data-block.styles'

const DataBlock = (props: DataBlockProps): JSX.Element => {
  const {
    textToCopy,
    avatar,
    canCopy,
    content,
    description,
    heading,
    icon,
    renderContentOnly,
    tooltip,
    title,
    sectionName,
    tabName,
    onCopy,
    ...others
  } = props as DataBlockCanCopy

  if (renderContentOnly) {
    return (
      <Container hasAvatar={false} hasIcon={false}>
        {content}
      </Container>
    )
  }

  const renderIcon = icon && <IconContainer data-testid="data-block:icon">{icon}</IconContainer>

  const renderAvatar = avatar && (
    <AvatarContainer>
      <Avatar>{avatar}</Avatar>
    </AvatarContainer>
  )

  const renderHeading = heading && <Heading as="h3">{heading}</Heading>

  const renderTitle = title && <DataBlockTitle tooltip={tooltip} title={title} />

  const renderDescription = title && description && (
    <DataBlockDescription
      {...description}
      textName={title}
      textToCopy={textToCopy}
      sectionName={sectionName}
      tabName={tabName}
      avatar={Boolean(avatar)}
      canCopy={canCopy}
      onCopy={onCopy}
    />
  )

  return (
    <Container {...others} hasIcon={Boolean(icon)} hasAvatar={Boolean(avatar)}>
      {renderAvatar}

      <Content>
        {renderHeading}
        {renderTitle}
        {renderDescription}
        {content}
        {renderIcon}
      </Content>
    </Container>
  )
}

export { DataBlock, type DataBlockProps }
