import { type HttpQueryParams, type IHttpResult } from '@grupoboticario/vdi-mfe-utils'

interface API {
  getAttendances: ApiMethod
  createAttendance: ApiMethod
  getParentCode: ApiMethod
  getResellers: ApiMethod
  updateResellerAddInfo: ApiMethod
  getResellerGeneralData: ApiMethod
  getResellerComplementData: ApiMethod
  getResellerComplementDataV2: ApiMethod
  getResellerOrderHistory: ApiMethod
  getResellerFidelity: ApiMethod
  getResellerFidelityMovement: ApiMethod
  getResellerOrderDetail: ApiMethod
  getResellerCredit: ApiMethod
  getResellerCCRMovements: ApiMethod
  getResellerTitles: ApiMethod
  getResellerTitleLink: ApiMethod
  getTopPurchasedProducts: ApiMethod
  getSectors: ApiMethod
  getOrderHistorical: ApiMethod
  getOrderGifts: ApiMethod
  getOrderCustomFields: ApiMethod
  getMLDLink: ApiMethod
  getDistributionCenter: ApiMethod
}

type ApiMethod = <ApiResData>(params?: ApiParams) => Promise<IHttpResult<ApiResData, ApiResError>>
type ApiParams = HttpQueryParams['params']

interface ApiResError extends Error {
  messageType: ErrorTypes
  path: string
  statusCode: number
  timestamp: string
}

// TODO: segregate this enum and remove comments
enum ErrorTypes {
  // Validation errors
  INVALID_CPF = 'INVALID_CPF',
  INVALID_GERAID = 'INVALID_GERAID',
  INVALID_EMAIL = 'INVALID_EMAIL',
  INVALID_VALUE = 'INVALID_VALUE',

  // Generic errors
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
  NOT_FOUND = 'NOT_FOUND',

  // Atendance errors
  EXCEEDED_ATTENDANCE = 'EXCEEDED_ATTENDANCE',
  ATTENDANCE_RELEASED = 'ATTENDANCE_RELEASED',
  NOT_IN_STRUCTURE = 'NOT_IN_STRUCTURE',
  WITHOUT_STRUCTURE = 'WITHOUT_STRUCTURE',
  IN_ATTENDANCE_BY_OTHER_SUPERVISOR = 'IN_ATTENDANCE_BY_OTHER_SUPERVISOR',
  IN_ATTENDANCE_BY_YOURSELF = 'IN_ATTENDANCE_BY_YOURSELF',

  // Search errors
  SUPERVISOR_WITHOUT_STRUCTURE = 'SUPERVISOR_WITHOUT_STRUCTURE',
}

enum MetaState {
  IDLE,
  ERROR,
  LOADING,
  FILLED,
  NOT_FOUND,
  UNAVAILABLE,
}

export { ErrorTypes, MetaState }
export type { API, ApiParams, ApiResError }
