import { PromotionsSlug } from './promotions.types'

const renderHeaderColor = (isMultiOrganization: boolean, slug: string): PromotionsSlug => {
  if (isMultiOrganization) {
    return 'mlt' as PromotionsSlug
  }

  return slug.toLowerCase() as PromotionsSlug
}

export { renderHeaderColor }
