import { PropsWithChildren } from 'react'

import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerProps,
  FloraTypography as Typography,
} from '@grupoboticario/flora-react'

import { useTranslation } from '@shared/i18n'

interface PromotionDrawerProps extends PropsWithChildren, DrawerProps {}

const PromotionDrawer = ({ children, ...props }: PromotionDrawerProps): JSX.Element => {
  const { t } = useTranslation('promotions', { keyPrefix: 'detail' })

  return (
    <Drawer {...props}>
      <DrawerContent css={{ '@tablet': { width: '492px' } }}>
        <DrawerCloseButton />
        <DrawerHeader css={{ borderBottom: '1px solid $nonInteractiveOutline' }}>
          <Typography
            fontSize="subtitle"
            fontWeight="medium"
            css={{ color: '$nonInteractivePredominant' }}
          >
            {t('title')}
          </Typography>
        </DrawerHeader>
        <DrawerBody>{children}</DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export { PromotionDrawer }
