import { Flex, styled } from '@grupoboticario/flora-react'

const Form = styled('form', {
  paddingTop: '$6',
  paddingBottom: '$4',
})

const Header = styled(Flex, {
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '$1',
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: '$nonInteractivePredominant',
  paddingBottom: '$6',
})

export { Form, Header }
