import { ConsultSimilarBaseProps, SelectedAddressStateProps } from '../consult-similar-drawer'

interface AddressStepProps extends ConsultSimilarBaseProps {
  setSelectedAddress: (state: SelectedAddressStateProps) => void
}

enum DeliveryType {
  ADDRESS = 'ENDERECO_ENTREGA',
  PICK_UP = 'RETIRA_LOCAL',
}

interface ResellerDistributionCenterDTO {
  resellerAddresses: ResellerAddressesProps[]
}

interface ResellerAddressesProps {
  id: number
  name: string
  deliveryType: DeliveryType
  address: string
}

export { AddressStepProps, DeliveryType, ResellerDistributionCenterDTO }
