import { Flex, styled } from '@grupoboticario/flora-react'

const Container = styled(Flex, {
  flexDirection: 'column-reverse',
  gap: '$2',

  '@tablet': {
    flexDirection: 'row',
  },

  button: {
    minWidth: '160px',

    '&:first-of-type': {
      'span > span': {
        display: 'inline-block',

        '&:nth-child(1)': {
          '@tablet': {
            display: 'none',
          },
        },

        '&:nth-child(2)': {
          display: 'none',

          '@tablet': {
            display: 'inline-block',
          },
        },
      },
    },
  },
})

export { Container }
