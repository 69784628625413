import { useResellerDataView } from '@reseller-data/hooks'
import { LoadingDataBlock } from '@shared/components'
import { useTranslation } from '@shared/i18n'

const ParentCode = (): JSX.Element => {
  const { t } = useTranslation('parentCode')
  const { model, loading, filled, error, refetch } = useResellerDataView()

  const notFound = filled && !model?.cp
  const notFoundMessage = notFound ? t('cpNotFound') : ''
  const cpNumber = parseInt((model?.cp ?? '').trim(), 10)

  return (
    <LoadingDataBlock
      title={t('cp')}
      description={{
        text: isNaN(cpNumber) ? '---' : cpNumber.toString(),
      }}
      isLoading={loading}
      error={error}
      notFound={notFoundMessage}
      onRetry={refetch}
    />
  )
}

export { ParentCode }
