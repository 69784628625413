interface PromotionsProps {
  resellerId: string
}

interface PromotionsDTO {
  promotions: PromotionProps[]
  pagination: PaginationProps
}

interface PaginationProps {
  totalPages: number
  page: number
  pageSize: number
}

interface PromotionProps {
  name: string
  slug: string
  description: string
  classificationName: string
  classificationId: number
  campaignId: string
  isMultiOrganization: boolean
}

enum PromotionsSlug {
  BOT = 'bot',
  OUI = 'oui',
  QDB = 'qdb',
  EUD = 'eud',
  MLT = 'mlt',
}

enum PromotionsColors {
  bot = '#00A775',
  oui = '#971B2F',
  eud = '#613A53',
  qdb = '#D43578',
  mlt = '#7E15E3',
}

export { PromotionsProps, PromotionsSlug, PromotionsColors, PromotionsDTO, PromotionProps }
