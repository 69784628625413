import { useResellerData } from '@reseller-data/hooks'
import { DataSection, GridMobile } from '@shared/components'
import { useTranslation } from '@shared/i18n'
import { Fragment } from 'react'
import { useFidelityData } from '../../hooks'
import { CurrentTier } from './current-tier'
import { HelpPanel } from './help-panel'
import { NextTierProgress } from './next-tier-progress'
import { PointPanel } from './point-panel'
import { RedeemablePoints } from './redeemable-points'

const Dashboard: React.FC = () => {
  const { t } = useTranslation('resellerFidelity')

  const { fidelity } = useResellerData()
  const { lastMovement } = useFidelityData()

  const { data, loading, error, filled, unavailable, refetch } = fidelity

  let lastUpdatedAtDescription

  if (data?.mcm) {
    const lastUpdatedDateObject = new Date(data.mcm.updatedAt)

    lastUpdatedAtDescription = t('lastUpdatedAt', {
      date: lastUpdatedDateObject.toLocaleDateString(),
      time: lastUpdatedDateObject.toLocaleTimeString(),
    })
  } else {
    lastUpdatedAtDescription = undefined
  }

  return (
    <DataSection
      data-testid="profile-data-fidelity"
      title={t('mcmFidelitySectionTitle')}
      description={lastUpdatedAtDescription}
      fallbackHeight={228}
      onRetry={refetch}
      unavailable={unavailable}
      loading={loading}
      filled={filled}
      error={error}
      data={data}
      render={({ mcm }) => (
        <Fragment>
          <GridMobile gap="$1" templateColumns="4fr 4fr" mobileTemplateColumns="4fr">
            <CurrentTier
              value={mcm.tier.name}
              nextTierValue={mcm.nextTier.name}
              imageUrl={mcm.tier.iconUrl}
            />
            <NextTierProgress
              remainingValue={mcm.nextTier.remainingValue}
              totalValue={mcm.nextTier.totalValue}
              name={mcm.nextTier.name}
            />
          </GridMobile>
          <GridMobile gap="$1" templateColumns="4fr 4fr 4fr" mobileTemplateColumns="4fr">
            <RedeemablePoints amount={mcm.availablePoints} lastMovement={lastMovement} />
            <PointPanel
              value={mcm.releasePoints}
              title={t('releasingPointsTitle')}
              description={t('releasingPointsDescription')}
              prefix="+"
            />
            <PointPanel
              value={mcm.expiringPoints}
              title={t('expiringPointsTitle')}
              description={t('expiringPointsDescription')}
              prefix="-"
            />
          </GridMobile>
          <HelpPanel />
        </Fragment>
      )}
    />
  )
}

export { Dashboard }
