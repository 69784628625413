import { Fragment } from 'react'

import {
  FloraButton as Button,
  FloraInput as Input,
  FloraTypography as Typography,
} from '@grupoboticario/flora-react'

import { MagnifyingGlassIcon, PencilIcon } from '@grupoboticario/flora-react-icons'

import { useTranslation } from '@shared/i18n'

import { machine } from '../consult-similar-drawer'

import { Form, Header } from './search-step.styles'
import { SearchStepProps } from './search-step.types'

const SearchStep = ({ changeStep, selectedAddress, show }: SearchStepProps): JSX.Element | null => {
  const { t } = useTranslation('searchStep')

  if (!show) {
    return null
  }

  const handleClick = (): void => {
    changeStep(machine.search.on.back)
  }

  return (
    <Fragment>
      <Header>
        <Typography
          as="h4"
          color="$nonInteractivePredominant"
          fontSize="exceptionsAuxiliar"
          fontWeight="regular"
        >
          {t('title', { deliveryType: selectedAddress.type })}
          <Typography
            as="span"
            fontSize="bodySmallShort"
            fontWeight="medium"
            css={{ display: 'block' }}
          >
            {selectedAddress.name}
          </Typography>
        </Typography>

        <Button
          hierarchy="tertiary"
          has="iconLeft"
          icon={<PencilIcon color="$nonPrimaryButtonContent" size={16} />}
          onClick={handleClick}
        >
          {t('editAddress')}
        </Button>
      </Header>

      <Form>
        <Input
          buttonProps={{
            'aria-label': t('formSearch.ariaLabel'),
            has: 'iconOnly',
            icon: <MagnifyingGlassIcon />,
          }}
          id="product-search"
          label={t('formSearch.label')}
          labelPosition="external"
          placeholder={t('formSearch.placeholder')}
        />
      </Form>
    </Fragment>
  )
}

export { SearchStep }
