import { useFeatureFlag } from 'configcat-react'
import { Fragment } from 'react'

import { useTranslation } from '@shared/i18n'

import {
  FinancialData,
  GeneralData,
  Orders,
  ProfileData,
  PromotionalData,
  SituationData,
} from '../..'
import { ResellerDataTab } from '../../../reseller-data.types'
import { TabPanel } from './tab-panel'
import { Promotions } from '@reseller-data/modules/promotions'

interface TabPanelsProps {
  resellerId: string
}

const TabPanels: React.FC<TabPanelsProps> = ({ resellerId }) => {
  const { t } = useTranslation('tabPanels')

  const { value: ordersTab } = useFeatureFlag('ordersTab', false)
  const { value: showNewPromotions } = useFeatureFlag('newPromotions', false)

  const renderPromotions = (): JSX.Element => {
    if (showNewPromotions) {
      return <Promotions resellerId={resellerId} />
    }

    return <PromotionalData />
  }

  return (
    <Fragment>
      <TabPanel aria-label={t('generalDataTabContainer')} value={ResellerDataTab.GENERAL}>
        <SituationData />
        <GeneralData />
      </TabPanel>

      <TabPanel aria-label={t('profileDataTabContainer')} value={ResellerDataTab.PROFILE}>
        <SituationData />
        <ProfileData resellerId={resellerId} />
      </TabPanel>

      {ordersTab && (
        <TabPanel aria-label={t('ordersDataTabContainer')} value={ResellerDataTab.ORDERS}>
          <Orders resellerId={resellerId} />
        </TabPanel>
      )}

      <TabPanel aria-label={t('financialDataTabContainer')} value={ResellerDataTab.FINANCIAL}>
        <SituationData />
        <FinancialData resellerId={resellerId} />
      </TabPanel>

      <TabPanel aria-label={t('promotionalDataTabContainer')} value={ResellerDataTab.PROMOTIONAL}>
        <SituationData />
        {renderPromotions()}
      </TabPanel>
    </Fragment>
  )
}

export { TabPanels }
